// material-ui
//import { useTheme } from '@mui/material/styles';
import { FunctionComponent } from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

//{theme.palette.primary.main}

const Logo: FunctionComponent = () => {
//  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <svg width="180" height="50" viewBox="0 0 92 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FEFEFE" d="M0 0L99.5625 0C99.8122 0.37692 99.9325 0.740231 99.9636 1.18901C100.413 7.67962 100.017 14.4677 100.012 20.9847C100.01 23.5889 100.426 28.766 99.9286 31.1352C99.8646 31.4404 99.7332 31.7401 99.5625 32L0 32L0 0Z"/>
      <path fill="#0D2D3F" d="M4.71465 18.3003C3.6467 18.0463 2.85566 18.7517 1.92444 17.997C1.81702 17.4546 1.87519 17.3197 2.07611 16.8005C2.30892 16.6448 2.56708 16.4071 2.84549 16.3754C3.99461 16.2445 4.84254 17.0134 5.68282 17.6844C6.53393 18.8399 7.30242 20.6175 8.68788 21.1901C9.40453 21.2363 10.2003 21.1066 10.7102 21.6842C10.8715 21.8669 11.0015 22.0988 11.1264 22.3081C10.0982 22.3589 8.83196 22.4374 7.85761 22.0827C6.13788 21.4567 5.43474 19.8423 4.71465 18.3003Z"/>
      <path d="M75.9614 13.3727C77.0866 13.5698 76.7404 13.7935 78.0798 13.6348C78.6876 13.5628 78.9478 13.6162 79.4096 13.9793Q79.4231 14.0609 79.425 14.1436C79.4637 15.6317 77.9403 15.6553 77.5411 16.6119C77.135 17.5852 77.656 18.6147 76.9956 19.627C77.1347 19.99 77.2968 20.3845 77.3493 20.771C77.4227 21.3111 77.4595 21.5518 77.1214 21.9969C76.5899 22.0168 76.5219 22.0087 76.097 21.6904C75.5124 19.2262 75.9511 15.95 75.9614 13.3727Z"/>
      <path d="M66.1934 10.1183L67.0945 10.1663C67.5454 10.6265 67.5327 11.4366 67.5756 12.0422C67.667 13.3309 67.826 21.0124 67.5297 21.6209C67.4547 21.7749 67.2174 21.9809 67.0988 22.1176C66.8647 22.1159 66.552 21.9857 66.3217 21.9223C65.371 20.2 66.0722 12.4474 66.1934 10.1183Z"/>
      <path d="M87.6932 13.624C88.5249 13.4229 89.3309 13.2467 90.1217 13.7095C90.721 14.0603 91.2311 14.8692 91.4041 15.5349C91.5212 15.9856 91.4592 16.043 91.2373 16.4225C90.268 16.4883 89.9527 15.5612 89.151 15.0435C88.8665 14.8598 88.7825 14.9245 88.4669 14.993Q88.2938 15.1703 88.1855 15.3931C87.6781 16.4466 87.5828 18.2124 87.9588 19.3066C88.2013 20.0124 88.4401 20.4551 89.1092 20.7801C89.5599 20.402 89.5457 20.2003 89.6806 19.6233C90.28 19.2862 90.5342 19.0145 91.2308 19.136L91.444 19.5869C91.4025 20.2037 91.2327 20.6207 90.8912 21.126C90.3489 21.9283 89.7438 22.4008 88.786 22.5867C88.4045 22.5468 88.0486 22.4772 87.7444 22.2284C86.7886 21.4466 86.3088 19.8095 86.1957 18.6306C86.0165 16.7627 86.5055 15.0614 87.6932 13.624Z"/>
      <path fill="#07213B" d="M12.3204 19.3318C12.6674 20.0268 12.9632 20.6996 13.5778 21.2022C15.0969 22.6499 21.0041 28.9481 22.6631 29.0875L23.7406 28.3609C23.5799 29.112 23.462 29.1815 22.8887 29.661L23.0693 29.7679L23.2092 30.45L22.6167 30.6764C20.1628 30.5218 14.6149 24.1977 12.6011 22.3356L11.1264 22.3081C11.0015 22.0988 10.8715 21.8669 10.7102 21.6842C10.2003 21.1066 9.40453 21.2363 8.68788 21.1901C10.5 21.0574 11.0761 20.5499 12.3204 19.3318Z"/>
      <path d="M68.4291 10.3737C69.5904 10.1566 73.9861 9.87729 74.9001 10.4563C75.1032 10.9695 75.0037 11.3877 74.8947 11.9133C74.1999 12.5029 73.9959 12.0754 73.2218 12.2385C71.9119 12.5144 73.1229 18.3399 72.1102 19.8835C72.2761 20.2129 72.5098 20.6171 72.5639 20.9828C72.6507 21.5695 72.5287 21.6978 72.1924 22.1426L71.6986 22.1048C69.9222 20.3009 71.7092 14.884 70.7878 12.4281C70.0695 12.0198 69.9193 12.1078 69.1429 12.2498C68.6027 11.7677 68.5489 11.0487 68.4291 10.3737Z"/>
      <path d="M92.7334 10.1215C93.0464 10.0876 93.3761 10.1545 93.6868 10.1949Q93.8542 10.408 93.9567 10.6588C94.5295 12.0835 94.2312 15.4024 93.6368 16.7987C94.3659 16.2837 96.5874 13.6116 97.3354 12.7122C97.3921 13.0352 97.442 13.3828 97.4199 13.7115C97.343 14.8554 96.2648 16.1592 95.443 16.8772C96.5379 17.6897 97.4319 20.2725 97.6658 21.605C97.7431 21.8672 97.7031 21.92 97.6053 22.1758L96.9481 22.1783C96.1341 21.8439 95.294 19.6336 94.7164 18.8524L94.4217 18.8685C94.1831 19.8705 94.0227 20.8905 93.849 21.9053L93.4635 22.0864L93.1111 21.8444C92.0633 19.8282 92.7002 12.707 92.7334 10.1215Z"/>
      <path d="M60.6724 13.6234C61.4423 13.4615 62.2468 13.2719 63.0054 13.5827C63.5729 13.8152 63.9454 14.3094 64.173 14.8626C64.8326 16.4659 65.058 20.2247 64.3717 21.874C64.1781 21.9772 63.9583 22.0668 63.7413 22.1023C62.2829 22.3414 60.791 22.2113 59.6031 21.3679C59.4636 20.5726 59.2939 19.7227 59.3628 18.9123C59.518 17.0848 61.6554 17.4949 62.7575 16.5703C63.0009 16.0458 63.1299 15.718 62.9748 15.1463C62.0376 14.8969 60.9047 15.5613 60.0183 15.9245L59.6852 15.7597C59.767 14.9456 60.2465 14.297 60.6724 13.6234Z"/>
      <path d="M81.1515 13.6241C81.8466 13.4646 82.5843 13.2666 83.2858 13.4939C83.8686 13.6828 84.2893 14.124 84.5619 14.6603C85.4489 16.4053 85.5006 19.9091 84.8847 21.783C83.1923 21.9405 81.6066 22.4027 80.0591 21.6444C80.072 21.6023 80.0818 21.5605 80.0914 21.5176C80.2993 20.5939 79.7581 19.5673 80.0304 18.5528C80.4091 17.1416 82.6048 17.3711 83.3606 16.22C83.3344 15.6568 83.2626 15.5228 82.9519 15.0497C81.9001 15.0717 81.3562 16.1121 80.3409 16.0625L80.0468 15.624C80.0176 14.8741 80.7164 14.1862 81.1515 13.6241Z"/>
      <path fill="#FEFEFE" d="M82.1953 18.3728C82.532 18.3225 82.8825 18.3578 83.2213 18.3734L83.5704 18.8456C83.5711 19.0744 83.5456 19.3273 83.4416 19.534C83.1414 20.1297 82.7213 20.4355 82.0943 20.6496C81.7058 20.4472 81.5083 20.169 81.2595 19.8198C81.3886 19.1409 81.7417 18.8761 82.1953 18.3728Z"/>
      <path d="M54.638 13.6219C55.2795 13.4459 55.9044 13.3244 56.5356 13.6086C57.3054 13.9554 57.7787 14.6293 58.0689 15.4008C58.7207 17.1338 58.5495 19.4917 57.776 21.1633C57.4465 21.8753 56.9747 21.9558 56.3053 22.1983Q56.1954 22.2295 56.082 22.2439C55.4828 22.3163 54.7713 22.1782 54.2996 21.7969C53.5774 21.213 53.2572 20.1695 53.1689 19.2784C52.9606 17.1775 53.2872 15.2695 54.638 13.6219Z"/>
      <path fill="#F5F9EF" d="M55.0298 15.1201L55.863 15.0982C56.1263 15.2411 56.4745 15.4078 56.6666 15.6395C57.0222 16.0686 56.9725 16.2629 56.9234 16.7849L56.3089 17.2035C55.8534 17.202 55.4583 17.0487 55.034 16.8992C54.7167 16.2305 54.901 15.8332 55.0298 15.1201Z"/>
      <path fill="#FEFEFE" d="M54.5875 18.6234Q55.0586 18.5724 55.5306 18.5306C56.2997 18.4626 57.2688 18.3412 57.8767 18.8506C57.6778 19.407 57.056 19.684 56.5757 19.9699C56.2257 20.3823 56.1591 20.5902 55.6105 20.6782C54.99 20.2643 54.8096 19.2985 54.5875 18.6234Z"/>
      <path d="M45.492 10.1141L46.3814 10.0837L46.8223 10.5355C47.2743 11.8998 47.0457 13.6449 46.6864 15.0138L49.8453 14.9893C50.5088 13.5799 49.9481 11.818 50.4745 10.3071C50.9697 10.116 51.0195 10.1217 51.5182 10.3048C51.7337 10.5573 51.8762 10.8489 51.9468 11.1735C52.2093 12.38 52.2713 20.9838 51.7234 21.8275C51.5683 22.0664 51.0826 22.0845 50.8044 22.1444L50.2942 21.9576C49.6662 20.7316 50.2761 18.4983 49.8854 17.019L46.5993 16.9893C47.029 17.8989 47.0524 19.0198 47.0244 20.0093C47.0019 20.8012 46.7757 21.6148 46.1866 22.1723L45.8547 22.0554C44.8634 20.1108 45.797 12.8081 45.492 10.1141Z"/>
      <path fill="#07213B" d="M22.3338 5.8893C24.071 4.66531 25.5607 4.08222 27.688 3.90883C29.2417 3.78219 32.0791 3.75927 33.2996 4.79433C33.5761 5.35531 33.6422 5.48015 33.5709 6.0961C32.3822 6.38944 29.4119 5.08641 27.7163 5.14557C25.5726 5.22037 24.0019 6.31159 22.5815 7.83246C20.7625 6.36256 19.0954 5.27204 16.6567 5.20275C14.2142 5.13335 11.6679 6.20487 9.90751 7.87649C8.07766 9.61402 7.40925 11.4892 7.34293 13.9499C7.90715 15.263 8.67496 16.273 9.67785 17.2825L10.0566 17.7146C9.96441 17.9778 9.76838 18.2227 9.61399 18.4543C8.90664 18.4112 8.2192 17.7889 7.69825 17.3505C6.63483 16.4557 6.10222 15.3102 5.9847 13.9346C5.79273 11.6873 6.74876 9.49509 8.1751 7.80312C10.0644 5.56196 12.8823 4.0215 15.8147 3.7733C18.2962 3.56326 20.4467 4.30027 22.3338 5.8893Z"/>
      <path fill="#FE665E" d="M18.88 21.4614C18.9079 20.6019 18.7808 19.2029 19.3929 18.5443C20.6506 19.2915 21.6065 23.5469 22.421 25.0816C23.584 22.9068 24.17 20.49 25.3818 18.3596L25.6885 18.3507C26.1667 19.225 25.61 20.1586 26.2678 20.8994C27.0459 20.6917 27.6967 19.8366 28.2407 19.2643C29.0713 19.3 31.8564 19.0458 32.3821 19.3284C32.4362 19.3575 32.487 19.3865 32.5371 19.4222C32.4087 21.3331 25.0654 26.2844 23.7406 28.3609L22.6631 29.0875C21.0041 28.9481 15.0969 22.6499 13.5778 21.2022C12.9632 20.6996 12.6674 20.0268 12.3204 19.3318C13.7601 19.2893 15.2096 19.2099 16.6496 19.2487C17.365 20.0075 18.0665 20.8062 18.88 21.4614Z"/>
      <path fill="#FE665E" d="M14.8135 7.82492C16.1655 7.38728 17.3784 7.4168 18.6637 8.07191C19.7641 8.63276 20.7616 9.50429 21.5176 10.4805C21.4305 12.4904 21.3971 14.5252 21.1141 16.5185L20.6142 16.6308C20.0388 16.0676 19.7503 15.2567 19.018 14.9537L18.4309 15.201C18.2413 15.9921 18.3334 16.7392 17.7504 17.314C15.6262 17.8419 11.7857 16.6698 10.0566 17.7146L9.67785 17.2825C9.85732 16.2378 9.55371 14.8666 9.53893 13.7757C9.73993 13.0681 9.93622 12.3175 10.2349 11.6442C11.137 9.6103 12.8126 8.59438 14.8135 7.82492Z"/>
      <path fill="#FEFEFE" d="M14.7241 9.3214L15.1291 9.89392C14.7797 11.199 13.274 12.8208 12.3917 13.835L12.0126 13.9082L11.5753 13.4941C12.2779 11.52 12.9461 10.4785 14.7241 9.3214Z"/>
      <path fill="#FE665E" d="M27.3459 7.53052C29.3571 7.44858 30.974 7.87963 32.5932 9.10614C34.0351 10.1985 35.181 11.8262 35.4287 13.6442C35.5843 14.7864 35.5276 16.4187 34.8059 17.3664C33.1226 17.6685 31.1556 17.4797 29.4375 17.4711C28.624 17.494 27.8894 17.544 27.1264 17.2114C26.4084 16.5937 26.2762 15.1281 26.0796 14.2272C25.2933 15.3032 24.4533 16.3306 23.6084 17.3605C23.6054 15.2278 22.8238 12.5242 23.6038 10.5173C24.2549 8.84201 25.8154 8.20246 27.3459 7.53052Z"/>
      <path fill="#FEFEFE" d="M29.3371 8.79528C30.0529 8.90274 30.8563 9.17974 31.4825 9.53833C32.1252 9.90632 32.8215 10.3865 33.0213 11.1419C33.1563 11.6528 32.9858 11.7929 32.735 12.2291C31.8751 11.3436 30.7536 10.7644 29.8477 9.93053C29.3822 9.50214 29.3624 9.42577 29.3371 8.79528Z"/>
      <path fill="#0D2D3F" d="M37.8988 8.67008C36.7243 6.96866 34.9505 7.04296 35.4946 4.09324C35.6342 3.33618 36.0191 2.67808 36.6599 2.23951C37.5991 1.59666 38.7704 1.45379 39.8715 1.66355C40.8841 1.85646 41.9465 2.4201 42.5323 3.29155C42.9034 3.84347 43.0353 4.4909 42.9022 5.14333C42.6218 6.51861 41.5906 7.58052 40.4625 8.3213Q40.1408 8.53172 39.8132 8.73289C39.8326 10.7151 40.0071 14.4857 38.5331 15.9777Q38.5095 16.0016 38.4853 16.0249L39.4463 17.2495C40.372 16.8472 41.5134 16.1819 42.5167 16.5781C42.8681 16.7168 43.2712 16.8679 43.4954 17.1746C43.2205 17.7001 42.9707 18.0686 42.3537 18.2542C41.6607 18.4627 40.786 18.1092 40.0668 18.1379C40.0195 19.1588 39.4655 20.3763 38.7052 21.069C37.3573 22.2972 35.3557 22.3867 33.635 22.302Q32.9055 22.2651 32.177 22.2109C31.6821 23.3627 24.6461 29.8939 23.4593 30.3646Q23.3366 30.4141 23.2092 30.45L23.0693 29.7679L22.8887 29.661C23.462 29.1815 23.5799 29.112 23.7406 28.3609C25.0654 26.2844 32.4087 21.3331 32.5371 19.4222Q32.593 19.414 32.6484 19.4025C33.4901 19.2247 34.265 18.5183 34.7182 17.8164C35.7343 17.1285 36.6957 16.348 37.174 15.1849C38.0669 14.3688 38.2113 13.0222 38.2644 11.8723C38.3076 10.9354 38.3554 9.48958 37.8988 8.67008Z"/>
      <path fill="#FEFEFE" d="M38.4853 16.0249L39.4463 17.2495C39.2562 18.1278 37.9047 20.0869 37.1427 20.5703C36.4124 21.0336 35.249 21.1871 34.4008 20.995Q34.2451 20.9581 34.0959 20.9005C34.2274 19.9813 35.3175 19.7194 35.4879 18.9187C35.5006 18.8589 35.511 18.8019 35.5158 18.7409C35.7383 18.6523 35.9592 18.5208 36.1673 18.4015C36.8696 17.9987 37.7884 17.2693 38.0075 16.4578L38.4853 16.0249Z"/>
      <path fill="#FEFEFE" d="M38.5986 2.87009C39.1143 2.81507 39.8109 2.90242 40.2691 3.1613C40.7606 3.43897 41.1168 3.97179 41.2639 4.50973C41.4151 5.06239 41.3036 5.65759 41.0172 6.14838C40.5927 6.87593 40.0292 6.95314 39.2959 7.15091C38.9586 7.14961 38.5656 7.1456 38.2437 7.02888C37.7307 6.84289 37.3198 6.48813 37.0899 5.99136C36.8354 5.44126 36.8513 4.81233 37.0633 4.25102C37.3489 3.49498 37.9087 3.18348 38.5986 2.87009Z"/>
    </svg>
  );
};

export default Logo;
